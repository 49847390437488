<template>
  <figure>
      <svg 
        :width="size" :height="size"
        enable-background="new 0 0 184.313 184.313" viewBox="0 0 184.313 184.313" xmlns="http://www.w3.org/2000/svg"><path d="m180.323 147.777-53.048-45.373 55.314-66.784c2.642-3.191 2.197-7.918-.992-10.56-3.19-2.643-7.918-2.199-10.561.992l-60.022 72.469c-.304.366-.562.754-.784 1.156v-68.841c0-4.142-3.357-7.5-7.5-7.5h-9.521c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h2.021v93.253l-81.806-105.353c-2.541-3.272-7.253-3.864-10.524-1.324-3.272 2.54-3.864 7.252-1.324 10.523l95.229 122.641c1.448 1.866 3.652 2.901 5.925 2.901.812 0 1.633-.132 2.431-.405 3.031-1.039 5.068-3.89 5.068-7.095v-46.55c.423.767.981 1.476 1.686 2.079l58.658 50.171c1.413 1.209 3.146 1.8 4.871 1.8 2.116 0 4.22-.891 5.703-2.625 2.693-3.148 2.324-7.883-.824-10.575z"/>
      </svg>
  </figure>
</template>

<script>
export default{
  name:'iconVk',
  props: {
    size: {
      type: String,
      requred: true
    }
  },
  data(){
    return{}
  },
  methods:{
  }
}
</script>

<style lang="scss">
</style>
